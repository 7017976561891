@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    scroll-behavior: smooth;
}

@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

.animation-desaparecer {
    animation: fadeOut 5s ease-in-out forwards;
}